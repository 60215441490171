<template>
  <div>
    <PageMessage v-if="!isRegisterCompleted">
      <Alert
        alert-type="info"
        :show-icon="showIconMediumOrLargeScreen"
        class="alert"
      >
        <div>
          <router-link
            to
            @click.native="$bus.$emit('global-initOnboard')"
          >
            <strong>Ganhe {{ getValeuAfterCompleteRegister }} em créditos para
              realizar consultas!</strong>
          </router-link>
          Complete o seu cadastro e receba mais créditos para consultar.
        </div>
      </Alert>
    </PageMessage>

    <div class="impersonate-container">
      <div class="box-impersonate">
        <div
          class="container"
          :class="{ 'container-old-version': pageLayoutOldVersion }"
        >
          <div class="main-impersonate">
            <div class="impersonate-person">
              <span class="user-company-prefix">{{ prefix }}</span>


              <span
                v-if="!isCompanies"
                class="user-company-name limited-content"
              >{{ getDisplayUserOrCompanyName }}</span>

              <a
                v-else
                href=""
                class="user-company-name limited-content"
                title="Mudar conta"
                @click.prevent="openModalChangeAccount"
              >{{ getDisplayUserOrCompanyName }}</a><span>{{ sulfix }}</span>

              <!-- <InputSelect
                v-if="forceUpdate"
                v-model="localCompanyUID"
                :disabled="disableInternal"
                :class="[disableInternal ? 'selectDisablePaddingRight' : '']"
                @change="impersonate(localCompanyUID)"
                :dataSource="userCompaniesDataSource"
                autoWidth="true"
                class="select-impersonate"
              />{{ sulfix }} -->

              <ModalDialogTutorial
                v-if="showDialogContexto"
                tutorial-name="tutorialContexto"
                indicator
                @closing="showDialogContexto = false"
              >
                <template #body>
                  <TalkContainer>
                    <TalkContent>
                      <p>
                        Você está usando o Exato como
                        <strong>{{ fromStoreUserFirstName }}</strong>.
                      </p>
                      <p class="mb-0">
                        Aqui você pode escolher entre sua conta pessoal ou de
                        <strong>empresa</strong>.
                      </p>
                    </TalkContent>
                  </TalkContainer>
                </template>
                <!-- https://stackoverflow.com/questions/67738496/how-to-handle-a-slots-onclick-event-in-vuejs -->
                <template #footer="{ closing }">
                  <div class="button-box">
                    <Button
                      class="mr-0"
                      btn-style="primary"
                      btn-size="micro"
                      @click="closing"
                    >
                      Entendi
                    </Button>
                  </div>
                </template>
              </ModalDialogTutorial>

              <ModalDialogTutorial
                v-else-if="showModalTutorialChooseCompany"
                indicator
                @closing="showModalTutorialChooseCompany = false"
              >
                <template #body>
                  <TalkContainer>
                    <TalkContent>
                      <p class="mb-0">
                        Aqui você pode escolher entre sua conta pessoal ou de
                        <strong>empresa</strong>.
                      </p>
                    </TalkContent>
                  </TalkContainer>
                </template>
                <!-- https://stackoverflow.com/questions/67738496/how-to-handle-a-slots-onclick-event-in-vuejs -->
                <template #footer="{ closing }">
                  <div class="button-box">
                    <Button
                      class="mr-0"
                      btn-style="primary"
                      btn-size="micro"
                      @click="closing"
                    >
                      Entendi
                    </Button>
                  </div>
                </template>
              </ModalDialogTutorial>
            </div>

            <transition name="fade">
              <div
                v-if="showPdfPasswordInstruction || (!hideCreditsInfo && !userIsPos)"
                class="impersonate-widgets"
              >
                <pdf-password-instruction-widget
                  v-if="showPdfPasswordInstruction"
                  pdf-password="000000"
                  :pdf-password-type="PdfPasswordType.PersonDocumentFirstSixNumericDigits
                  "
                />
                <div
                  v-if="!hideCreditsInfo && !userIsPos"
                  class="credit-button"
                >
                  <div class="credit-content">
                    <div class="credit-dollar-box">
                      <div class="credit-dollar">
                        <svg class="icon icon-medium icon-dollar">
                          <use xlink:href="/images/icon-lib.svg#icon-dollar" />
                        </svg>
                      </div>
                    </div>
                    <div class="credit-text-box">
                      <div class="credit-title">
                        créditos restantes
                      </div>
                      <div
                        class="credit-value"
                        :class="{ 'body-text-1': loadingBalance }"
                      >
                        {{ balanceValue }}
                      </div>
                    </div>

                    <Button
                      v-if="!hideButtonBuy && ($mq === 'lg' || $mq === 'xl')"
                      :btn-style="getButtonStyle"
                      btn-size="micro"
                      class="button"
                      :class="getButtonBgColor"
                      @click="$router.push({ name: 'ComprarCredito' })"
                    >
                      Comprar
                    </Button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <!-- container end -->
      </div>
    </div>

    <ModalDialogTutorial
      v-if="showModalPrice"
      width="452px"
      center
      tutorial-price-change="tutorialPriceChange"
      @closing="showModalPrice = false"
    >
      <template #body>
        <TalkContainer class="mb-1">
          <TalkContent>
            <p class="mb-0">
              Os preços dos relatórios serão reajustados a partir de
              <strong>10/01/2025</strong>
            </p>
          </TalkContent>
        </TalkContainer>

        <TableCorporate class="table-check mb-2">
          <tbody>
            <tr>
              <td
                class="col-description"
                scope="row"
              >
                Relatório de Pessoa Física
              </td>
              <td class="col-value text-right">
                <strong>R$ 39,90</strong>
              </td>
            </tr>
            <tr>
              <td
                class="col-description"
                scope="row"
              >
                Relatório de Pessoa Jurídica
              </td>
              <td class="col-value text-right">
                <strong>R$ 39,90</strong>
              </td>
            </tr>
            <tr>
              <td
                class="col-description"
                scope="row"
              >
                Relatório PF + Análise de Crédito Quod
              </td>
              <td class="col-value text-right">
                <strong>R$ 49,90</strong>
              </td>
            </tr>
            <tr>
              <td
                class="col-description"
                scope="row"
              >
                Relatório PJ + Análise de Crédito Quod
              </td>
              <td class="col-value text-right">
                <strong>R$ 49,90</strong>
              </td>
            </tr>
          </tbody>
        </TableCorporate>

        <div class="price-disclaimer">
          <!-- <TooltipIcon
            iconClass="icon-small text-gray-300"
            text="Nestes casos, <strong>aumentaremos proporcionalmente o seu saldo </strong> para que você possa emitir a mesma quantidade de relatórios que emitiria antes do reajuste de preços."
          />O que acontece com os créditos adquiridos antes de 10/01/2025? -->


          <div class="mb-0-5 text-weight-bold">
            Possui créditos adquiridos antes de 10/01/2025?
          </div>

          <p>
            Nestes casos, <span class="text-weight-medium">aumentaremos proporcionalmente o seu saldo
            </span> para que você possa emitir a mesma quantidade de relatórios que emitiria antes do reajuste de
            preços
          </p>
        </div>
      </template>
      <!-- https://stackoverflow.com/questions/67738496/how-to-handle-a-slots-onclick-event-in-vuejs -->
      <template #footer="{ closing }">
        <div class="button-box">
          <Button
            class="mr-0"
            btn-style="primary"
            btn-size="micro"
            @click="closing"
          >
            Entendi
          </Button>
        </div>
      </template>
    </ModalDialogTutorial>

    <Portal to="app-end">
      <transition name="fade3">
        <ModalChangeAccount
          v-if="showModalChangeAccount"
          :width="modalChangeAccoutWidthDesktop"
          hide-footer
          @close="closeModalChangeAccount"
          @openChangeAccount="openModalChangeAccount"
        />
      </transition>

      <transition name="fade3">
        <ModalReportWithJusbrasil
          v-if="showModalReportWithJusbrasil"
          width="550px"
          @close="closeModalReportWithJusbrasil"
          @openChangeAccount="openModalReportWithJusbrasil"
        />
      </transition>
    </Portal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/input-select.vue";
import Button from "@/components/buttons/button.vue";
import HomeService from "@/services/service-providers/webapp-services/home-service.js";
import ModalCenter from "@/components/boxes/modal-center.vue";
import ModalCenterContent from "@/components/boxes/modal-center-content.vue";
import ModalDialogTutorial from "@/components/boxes/modal-dialog-tutorial.vue";

import { companyUidCookie } from "@/mixin/company-uid-cookie-mixin.js";
import { impersonateUser } from "@/mixin/impersonate-user-mixin.js";
import { checkUserBilling } from "@/mixin/check-user-billing-mixin.js";
import { iconAlert } from "@/mixin/icon-alert-mixin.js";
import { valueAfterCompleteRegister } from "@/mixin/value-after-complete-register-mixin.js";
import { goToElement } from "@/mixin/go-to-element-mixin.js";
// import { contextBehavior } from "@/mixin/context-behavior-mixin.js";
import { modalChangeAccount } from "@/mixin/modal-change-account-mixin";

import PdfPasswordInstructionWidget from "@/components/misc/pdf-password-instruction-widget.vue";
import PdfPasswordTypeEnum from "@/constants/pdf-password-type-enum";

import cookieHelpers from "@/utils/cookie-helpers.js";

const Alert = () => import("@/components/misc/alert.vue");
const PageMessage = () =>
  import(
    /* webpackChunkName: "PageMessage" */ "@/components/message/page-message.vue"
  );
const TalkContainer = () =>
  import(
    /* webpackChunkName: "TalkContainer" */ "@/components/talk/talk-container.vue"
  );
const TalkContent = () =>
  import(
    /* webpackChunkName: "TalkContent" */ "@/components/talk/talk-content.vue"
  );
const TableCorporate = () =>
  import(
    /* webpackChunkName: "TableCorporate" */
    "@/components/corporate/table/table-corporate.vue"
  );

const TooltipIcon = () =>
  import(
    /* webpackChunkName: "TooltipIcon" */ "@/components/shared/tooltip/tooltip-icon.vue"
  );

const ModalChangeAccount = () =>
  import(
    /* webpackChunkName: "ModalChangeAccount" */ "@/components/account/modal-change-account.vue"
  );

const ModalReportWithJusbrasil = () => import(
        /* webpackChunkName: "ModalReportWithJusbrasil" */ "@/components/dashboard/modal-report-with-jusbrasil.vue"
);

export default {

  components: {
    PdfPasswordInstructionWidget,
    InputSelect,
    ModalCenter,
    ModalCenterContent,
    ModalDialogTutorial,
    Button,
    Alert,
    PageMessage,
    TalkContent,
    TalkContainer,
    TableCorporate,
    TooltipIcon,
    ModalChangeAccount,
    ModalReportWithJusbrasil,
  },
  mixins: [
    companyUidCookie,
    impersonateUser,
    checkUserBilling,
    iconAlert,
    valueAfterCompleteRegister,
    goToElement,
    // contextBehavior,
    modalChangeAccount
  ],

  props: {
    prefix: {
      type: String,
      required: false,
      default: ""
    },

    sulfix: {
      type: String,
      required: false,
      default: ""
    },

    showPdfPasswordInstruction: {
      type: Boolean,
      required: false,
      default: false
    },

    hideCreditsInfo: {
      type: Boolean,
      required: false,
      default: false
    },

    hideButtonBuy: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    pageLayoutOldVersion: {
      type: Boolean,
      required: false,
      default: false
    },

    userHaveAnyCompany: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {

      showModalReportWithJusbrasil: false,

      localCompanyUID: "",
      balanceCondition: 1,
      forceUpdate: true,

      showModalDisabledFeature: false,
      showBuyCredit: false,

      balanceValue: "Carregando...",
      loadingBalance: true,
      showDialogContexto: false,
      showModalTutorialChooseCompany: false,
      showModalPrice: false,

      displayUserCompany: "",

    };
  },

  computed: {

    fromStoreCompanyUID: function () {
      return this.$store.getters["UserModule/impersonatedCompanyUid"];
    },

    getDisplayUserOrCompanyName() {

      // o usuário principal não tem impersonatedCompanyUid. Nesse caso exibe o firstname
      if (!this.fromStoreCompanyUID) {
        return this.fromStoreUserFirstName;
      }

      let userCompanies = this.$store.getters["UserModule/userCompanies"];
      if (userCompanies && userCompanies.length > 0) {
        // encontra empresa do usuário com base no companyUID selecionando anteriormente
        return userCompanies.find(
          item => item.companyUID === this.fromStoreCompanyUID
        ).name;
      }

    },

    isCompanies() {
      // mesmo que o usuário não tenha empresa, o userCompanies é preenchido com o usuário principal sem UID
      // considera que tem empresa se tiver mais de 1 item
      return this.$store.getters["UserModule/userCompanies"].length > 1;
    },


    PdfPasswordType() {
      return PdfPasswordTypeEnum;
    },
    userCompaniesDataSource: function () {
      let userCompanies = this.$store.getters["UserModule/userCompanies"];
      if (userCompanies && userCompanies.length > 0) {
        let dataSource = userCompanies.map(function (item) {
          return { value: item.companyUID, text: item.name };
        });
        dataSource[0].text = this.$store.getters["UserModule/userFirstName"];
        return dataSource;
      } else
        return [
          { value: "", text: this.$store.getters["UserModule/userFirstName"] }
        ];
    },

    disableInternal: function () {
      return (
        this.disabled ||
        this.$store.getters["UserModule/userCompanies"].length <= 1
      );
    },

    // fromStoreCompanyUID: function() {
    //   return this.$store.getters["UserModule/impersonatedCompanyUid"];
    // },

    fromStoreUserFirstName: function () {
      return this.$store.getters["UserModule/userFirstName"];
    },

    // Não chamado no momento. Ficava no cache
    formatedBalance: function () {
      if (this.$store.getters["UserModule/userBalance"] >= 0)
        return (
          "R$ " +
          this.$store.getters["UserModule/userBalance"]
            .toFixed(2)
            .replace(".", ",")
        );
      else return "Carregando...";
    },

    dollarColor: function () {
      if (this.balanceCondition == 2) return "#e73645";
      else return "#fcc52c";
    },

    getButtonBgColor: function () {
      return this.balanceCondition == 1 ? "bg-turquesa-400" : null;
    },

    getButtonStyle: function () {
      return this.balanceCondition == 2 //saldo baixo
        ? "primary"
        : "secondary";
    },

    isRegisterCompleted: function () {
      return (
        this.$store.getters["UserModule/userOnboarded"] &&
        this.$store.getters["UserModule/emailValidated"]
      );
    }
  },

  watch: {

    // monitorar se o usuário é POS
    // feito dessa forma devido a mudança de contexto e do 
    // currentBillingType ser iniciado como 1 (PRE-pago)
    // e depois alterado para 2 (POS-pago)
    userIsPos: function () {
      this.callShowModalPrice();
    },

    // seta valor do select para a ultima empresa acessada
    // o usuário principal não tem impersonatedCompanyUid: sem valor
    fromStoreCompanyUID: function () {
      this.localCompanyUID = this.fromStoreCompanyUID;
    },

    userCompaniesDataSource: function () {
      this.forceUpdate = false;
      let self = this;
      setTimeout(function () {
        self.forceUpdate = true;
      }, 1);
    },

    showModalPrice: function () {
      if (!this.showModalPrice) {
        // entra quando o modalPrice for fechado
        this.contextBehavior();
      }
    }
  },

  created() {
    let self = this;
    this.$bus.$on("global-refreshBalance", function () {
      self.refreshBalance();
    });


    this.$bus.$on("open-modal-tutorial-choose-company", function () {
      self.showModalTutorialChooseCompany = true;
      self.goToElementId("app", "start");
    });
  },

  mounted() {
    this.localCompanyUID = this.fromStoreCompanyUID;
    this.contextBehavior();
    this.refreshBalance();

    // impersonate-user-mixin.js
    // Pode ocorrer problema, pois o login sempre retorna o usuário principal	
    this.callShowModalPrice();

    this.openModalReportWithJusbrasil();
  },

  beforeDestroy() {
    this.$bus.$off("global-refreshBalance");
    // this.$bus.$off("global-changeAccount");
    this.$bus.$off("open-modal-tutorial-choose-company");
  },

  methods: {

    openModalReportWithJusbrasil() {

      // somente abre o modal se o usuário tiver o cookie do modal de contexto
      // e não tiver o cookie do modal de report with jusbrasil
      if (this.getCookieTutorialContexto() &&
        !this.getCookieModalReportWithJusbrasil()
      ) {
        this.showModalReportWithJusbrasil = true;
        this.setCookieModalReportWithJusbrasil();
      }
    },

    closeModalReportWithJusbrasil() {
      this.showModalReportWithJusbrasil = false;
    },

    setCookieModalReportWithJusbrasil: function () {
      let d = new Date();
      d.setTime(d.getTime() + (1080 * 24 * 60 * 60 * 1000));
      cookieHelpers.setCookie('ModalReportWithJusbrasil', 1, d);
    },

    getCookieModalReportWithJusbrasil: function () {
      return cookieHelpers.getCookie('ModalReportWithJusbrasil');
    },


    checkShowModalPrice() {

      //desabilitado
      return false;

      // exibe modal se não for pós e não tiver o cookie
      //return !this.getCookiePriceChange2025() && !this.userIsPos;

      // modal de mudança de preço anterior
      //return !this.getCookiePriceChange() && !this.userIsPos;
    },

    contextBehavior(pageWithoutSelectContext) {
      let userCompanies = this.$store.getters["UserModule/userCompanies"];

      if (userCompanies && userCompanies.length > 0) {
        // tratamento feito para o doccheck
        // deve entrar somente se a página tiver a de seleção de contexto
        if (!pageWithoutSelectContext) {
          // o checkShowTutorialContexto está no impersonate-user.vue
          if (this.checkShowTutorialContexto(userCompanies.length)) {
            this.showDialogContexto = true;
          }
        }

        //localCompanyUID - retornado no GetUserEnvironment: impersonatedCompanyUid
        // deveria retornar os campos da ultima empresa selecionada e não ter chamar
        // o impersonate
        if (this.localCompanyUID != null) {
          this.impersonate(true);
          return;
        }
      }
    },

    checkCookieStoredCompanyUID: function (userCompanies, storedCompanyUID) {
      return userCompanies.find(item => item.companyUID === storedCompanyUID);
    },

    checkShowTutorialContexto: function (userCompaniesLength) {
      if (this.showModalPrice) {
        return false;
      }

      // o userCompanies considera um usuário cadastrado sem empresa.
      // Acima e igual a 2 tem empresa cadastrada
      if (userCompaniesLength < 2) {
        return false;
      }

      // Exibe se ainda não acessou o contexto e ainda não clicou no botão entendi
      // cookie: localCompanyUID
      // cookie: localTutorialContexto
      if (!this.getCookieCompanyUid() && !this.getCookieTutorialContexto()) {
        return true;
      }
      return false;
    },

    refreshBalance: function () {
      let self = this;
      self.balanceValue = "Carregando...";
      self.loadingBalance = true;
      HomeService.getUserBalance()
        .then(function (response) {
          self.balanceCondition = response.data.data.balanceCondition;

          //avaliar se é usado em outro local
          self.$store.commit(
            "UserModule/userBalance",
            response.data.data.balance
          );

          self.loadingBalance = false;
          self.balanceValue = self.formatBalance(response.data.data.balance);
        })
        .catch(function (error) {
          self.$bus.$emit(
            "global-notification",
            error.response.data.message,
            true
          );
          self.loadingBalance = false;
        });
    },

    disabledFunctionAlert: function () {
      this.$bus.$emit("global-modal-disabled-function");
    },

    formatBalance: function (value) {
      if (value >= 0) return "R$ " + value.toFixed(2).replace(".", ",");
      else return "Carregando...";
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/sizes.less";
@import "../../styles/screen-size.less";

//Conteúdo interno nas páginas com tamanho fixo de 1256px
.container-old-version {
  max-width: 1256px;
  padding: 0;
}

.box-impersonate {
  padding: 0.75rem 0;
  background-color: #ebf4f3;
}

.impersonate-container {
  margin-bottom: 1.25rem;

  @media (min-width: @screenLarge) {
    min-height: 94px;
  }
}

.main-impersonate {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem; // 12px
  text-align: left;

  @media (min-width: @screenLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
  }
}

.impersonate-person {
  display: flex;
  // max-width: 940px;

  // no mobile com texto grande ainda precisa ser melhorado
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 1;
  color: @ColorPriTurquoise;
  text-align: left;

  @media (min-width: @screenLarge) {
    max-width: 72%;
    font-size: 1rem;
    line-height: 1.2;
  }

  >select {
    font-size: inherit;
    color: inherit;
    width: auto;
    max-width: 90%;
    border-bottom-width: 1px;
    border-bottom-color: @ColorPriTurquoise;

    &:not([disabled]) {
      /* webpackIgnore: true */
      background-image: url("/images/shape-down-turquoise.svg") !important;
    }

    @media (min-width: @screenLarge) {
      max-width: 600px;
    }
  }
}

.impersonate-widgets {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 0.75rem; // 12px;

  @media (min-width: @screenLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 3.75rem; // 60px;
  }
}

.select-impersonate {
  font-weight: 500;
  opacity: 1;

  @media (max-width: @screenSmall) {
    padding-left: 0;
  }
}

.selectDisablePaddingRight {
  padding-right: 0;
  padding-left: 0;
}

/deep/ option {
  font-size: 12px !important;
  color: inherit !important;
}

.user-company-prefix {
  margin-right: .25rem;
}

.user-company-name {
  font-weight: 500;

  @media (min-width: @screenLarge) {
    max-width: 75%;
    //max-width: 600px;
  }
}

a.user-company-name {
  text-decoration: underline;
}

.credit-button {
  z-index: 5;
  flex-basis: fit-content;
  background-color: @ColorPriTurquoise;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  color: @ColorPriWhite;
  display: inline-block;
  text-align: left;
  // overflow: hidden;
  // position: absolute;
  top: -7px;
  right: 0;
}

.credit-content {
  display: flex;
  align-items: center;
  min-width: 195px;
  height: 100%;
}

.credit-dollar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  width: 46px;
  height: inherit;
  border-right: 1px solid fade(@ColorSecGrey05, 30%);

  /* @media (min-width: @screenLarge) {
      width: 50px;
    } */
}

.credit-dollar {
  font-size: 28px;
  text-align: center;
  font-weight: 300;
  color: @ColorPriYellow;
}

.credit-text-box {
  display: flex;
  //padding: 0.3rem 1rem;

  flex-shrink: 0;
  padding: 0.4rem 0.75rem;

  @media (min-width: @screenLarge) {
    display: block;
    //padding: 0.4rem 0.75rem;
  }
}

.credit-title {
  // botão comprar não liberado
  margin-right: 0.25rem;
  font-size: 14px;
  //

  @media (min-width: @screenLarge) {
    margin-bottom: -2px;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 0.5px;
  }
}

.credit-value {
  // botão comprar não liberado
  font-size: 14px;
  font-weight: 500;
  //

  @media (min-width: @screenLarge) {
    font-size: 18px;
    min-height: 27px;
  }
}

.credit-hover {
  background-color: @ColorPriTurquoise;
  border-top: 1px solid fade(@ColorSecGrey05, 30%);
  padding: 1rem 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.button {
  max-width: 130px;
  margin-right: 0.75rem;
}

.icon-dollar {
  margin-left: 3px;
  width: 11px;
  height: 20px;
}

.alert {
  padding-left: 0;
  margin: 0;
}

.price-disclaimer {
  // display: flex;  
  // gap: 0.5rem;
  font-size: 0.875rem;

  // /deep/ .icon {
  //   margin-top: 4px;
  // }

}

.col-value {
  width: 72px;
  min-width: 72px;

  @media (min-width: @screenLarge) {
    width: 84px;
    min-width: 84px;
  }
}

</style>
