<template>
  <div
    class="main-box"
    :class="$mq === 'md' || $mq === 'sm' ? 'main-box-md' : ''"
  >
    <div
      class="banner-box"
      :class="theme"
    />

    <div class="login-box">
      <div class="fields-box">
        <LogoExato v-if="$mq === 'lg' || $mq === 'xl'" />
        <h2 v-if="$mq === 'sm' || $mq === 'md'">
          {{ $t("login") }}
        </h2>
        <h4 class="header-text">
          {{ $t("components.Login.text") }}
        </h4>
        <Alert
          v-if="pageError"
          class="alert"
          alert-type="danger"
          :show-icon="false"
          small-text
        >
          <slot v-if="messageError === 'userLoginError'">
            {{ message }}
            <a
              class="error-forgot-password-link"
              :href="localePath('LandingPage')"
              @click.prevent="recoverPassword"
            >{{ $t("components.Login.forgotYourPassword") }}</a>
          </slot>
          <slot v-else-if="messageError === 'userBlocked'">
            {{ message }}
            <a
              class="error-forgot-password-link"
              :href="localePath('LandingPage')"
              @click.prevent="recoverPassword"
            >{{ $t("components.Login.forgotMyPassword") }}</a>.
          </slot>
          <slot v-else>
            {{ message }}
          </slot>
        </Alert>
        <form
          class="form-login"
          @submit.prevent
        >
          <!-- https://community.hubspot.com/t5/Lead-Capture-Tools/Ignore-a-specific-non-Hubspot-form-from-being-collected/m-p/246957 -->
          <label
            for="amex"
            style="display:none"
          >AMEX</label>
          <input
            id="amex"
            type="hidden"
            name="amex"
            required=""
            value=""
          >
          <div>
            <InputText
              v-model="username"
              v-focus
              autocomplete="email"
              name="email"
              maxlength="100"
              :disabled="screenLocked"
              :placeholder="$t('components.Login.usernamePlaceholder')"
              @keyup.enter.native="login"
            />
          </div>
          <div>
            <InputPassword
              id="inputPassword"
              v-model="password"
              autocomplete="current-password"
              name="password"
              :type="passwordFieldType"
              maxlength="30"
              :disabled="screenLocked"
              :placeholder="$t('components.Login.passwordPlaceholder')"
              @keyup.enter.native="login"
            />

            <svg
              v-if="passwordFieldType === 'password'"
              class="icon icon-medium icon-eye input-icon-prepend"
              tabindex="-1"
              @click="switchVisibility"
            >
              <use xlink:href="/images/icon-lib.svg#icon-eye" />
            </svg>

            <svg
              v-if="passwordFieldType === 'text'"
              class="icon icon-medium icon-eye input-icon-prepend"
              tabindex="-1"
              @click="switchVisibility"
            >
              <use xlink:href="/images/icon-lib.svg#icon-eye-closed" />
            </svg>
          </div>
        </form>
        <div class="forgot-pass-box">
          <a
            class="forgot-pass-link"
            href="#"
            @click.prevent="recoverPassword"
          >{{ $t("components.Login.forgotMyPassword") }}</a>
        </div>
        <!-- <p v-if="message" class="error-message">{{ message }}&nbsp;</p> -->
        <div class="button-box">
          <Button
            class="button"
            type="button"
            btn-style="primary"
            :disabled="screenLocked"
            :loading="screenLocked"
            @click="login"
          >
            {{ $t("login") }}
          </Button>
        </div>
        <SocialLogin2
          font-color="text-ColorSecGrey47"
          border-color="bd-ColorSecGrey47"
          bg-color-divider="bg-ColorSecGrey03"
          @socialLoginStarted="socialLoginStarted"
          @loginSuccessful="loginSuccessful"
          @socialRegistrationRequested="socialRegistrationRequested"
          @socialLoginRequested="socialLoginRequested"
        />
        <div class="action-navigation">
          {{ $t("components.Login.noAccount") }}
          <router-link
            to
            @click.native="$bus.$emit('global-register')"
          >
            {{ $t("signup") }}
          </router-link>
        </div>

        <Portal to="app-end">
          <div
            id="recaptcha-login"
            key="recaptcha-login"
          />
        </Portal>
      </div>
    </div>
  </div>
</template>

<script>
import bradescoClaim from "@/router/bradesco/bradesco-claim";
import { passwordVisualHelper } from "@/mixin/password-visual-helper-mixin.js";
import { login } from "@/mixin/login-mixin.js";
import { checkWhatsAppText } from "@/mixin/whatsapp-param-text-mixin.js";
import { inputFocus } from "@/mixin/input-focus-mixin.js";
// import LoginService from "@/services/service-providers/webapp-services/login-service.js";
import googleCaptchaProvider from "@/services/captcha-providers/google.service.js";
import InputText from "@/components/inputs/input-text.vue";
import InputPassword from "@/components/inputs/input-password.vue";
import Button from "@/components/buttons/button.vue";
import SocialLogin2 from "@/components/misc/social-login-2.vue";
import Alert from "@/components/misc/alert.vue";
const LogoExato = () =>
  import(
    /* webpackChunkName: "LogoExato" */ "@/components/logo/logo-exato.vue"
  );

export default {

  components: {
    InputText,
    InputPassword,
    SocialLogin2,
    Alert,
    LogoExato,
    Button
  },
  mixins: [passwordVisualHelper, login, checkWhatsAppText, inputFocus],

  data() {
    return {
      username: "",
      password: "",
      message: "",
      screenLocked: false,
      tokenCompanyRegistration: "",
      pageError: ""
    };
  },

  computed: {
    googleCaptchaProviderForMixin() {
      return googleCaptchaProvider;
    },

    theme: function() {
      if (this.getRouteBaseName() === "LandingPageDev") return "banner-box-dev";
      if (this.getRouteBaseName() === "LandingPageRH") return "banner-box-rh";
      if (this.getRouteBaseName() === "LandingPageAutos")
        return "banner-box-veiculos";
      return "";
    }
  },

  watch: {
    username: function() {
      this.checkIfEmailBradesco();
    }
  },

  mounted: function() {
    this.tokenCompanyRegistration = this.$route.params.tokenCompanyRegistration;
    this.hasAccountEmail();
    this.$nextTick(function() {
      googleCaptchaProvider.initialize("recaptcha-login");
    });
  },

  destroyed: function() {
    googleCaptchaProvider.dispose();
  },

  methods: {
    hasAccountEmail: function() {
      if (this.getAccountJoinedData) {
        if (this.getAccountJoinedData.accountEmail) {
          this.completeUserEmail(this.getAccountJoinedData.accountEmail);
        }
      } else {
        this.completeUserEmail(sessionStorage.getItem("userMainEmail"));
      }
    },

    completeUserEmail: function(email) {
      if (email) {
        this.username = email;
        // problema - na landing generica tem um input com o mesmo id
        this.focusInput("inputPassword");
      }
    },

    checkIfEmailBradesco: function() {
      if (this.username != undefined && this.username.includes("@bradescobbi"))
        return;
      if (this.username.includes("@bradesco.com.br") || this.username.includes("@bradescoseguros.com.br")) {
        this.$router.push('/login/ad').then(() => {
          window.location.reload();
        });
      }
    },

    parseJwt: function(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    },

    hasBradescoClaim: function(credentials) {
      return credentials.extraClaim_0 == null ||
        credentials.extraClaim_0 == undefined ||
        !credentials.extraClaim_0.includes(bradescoClaim)
        ? false
        : true;
    },

    recoverPassword: function() {
      this.$emit("recoverPassRequested");
    },

    socialLoginStarted: function() {
      this.screenLocked = true;
    },

    loginSuccessful: function() {
      this.$emit("loginSuccessful");
    },

    socialRegistrationRequested: function(value) {
      this.$emit("socialRegistrationRequested", value);
    },

    socialLoginRequested: function(value) {
      this.$emit("socialLoginRequested", value);
    },

    cadastreSe: function() {
      window.location = this.localePath("Cadastro");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/colors.less";
@import "../../styles/screen-size.less";
@import "../../styles/input-group.less";

.main-box {
  height: 100%;
}

.banner-box {
  width: 50%;
  height: 100%;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  float: left;

  background-color: @turquesa-300;
}

.banner-box {
  /* webpackIgnore: true */
  background-image: url("/images/img-login-427x783-min.png");

  //superior a 1440
  @media only screen and (min-width: 1440px) {
    /* webpackIgnore: true */
    background-image: url("/images/img-login-641x1172-min.png");
  }
  //superior a 2048
  @media only screen and (min-width: 2049px) {
    /* webpackIgnore: true */
    background-image: url("/images/img-login-802x1467-min.png");
  }
  //superior a 3070
  @media only screen and (min-width: 3070px) {
    /* webpackIgnore: true */
    background-image: url("/images/img-login-1177x2152-min.png");
  }
}

/* .banner-box {
  background-image: url("/images/landing-page-generica-1350x688.jpg");

  //superior a 1440
  @media only screen and (min-width: 1441px) {
    background-image: url("/images/landing-page-generica-2048x1044.jpg");
  }
  //superior a 2048
  @media only screen and (min-width: 2049px) {
    background-image: url("/images/landing-page-generica-3840x1957.jpg");
  }
} */

/* .banner-box-rh {
  background-image: url("/images/banner-landing-rh-1366.jpg");

  //superior a 1440
  @media only screen and (min-width: 1441px) {
    background-image: url("/images/banner-landing-rh-2048.jpg");
  }
  //superior a 2048
  @media only screen and (min-width: 2049px) {
    background-image: url("/images/banner-landing-rh-3840.jpg");
  }
}

.banner-box-dev {
  background-image: url("/images/banner-landing-devs-1366.jpg");

  //superior a 1440
  @media only screen and (min-width: 1441px) {
    background-image: url("/images/banner-landing-devs-2048.jpg");
  }
  //superior a 2048
  @media only screen and (min-width: 2049px) {
    background-image: url("/images/banner-landing-devs-3840.jpg");
  }
}

.banner-box-veiculos {
  background-image: url("/images/banner-landing-veiculos-1366.jpg");

  //superior a 1440
  @media only screen and (min-width: 1441px) {
    background-image: url("/images/banner-landing-veiculos-2048.jpg");
  }
  //superior a 2048
  @media only screen and (min-width: 2049px) {
    background-image: url("/images/banner-landing-veiculos-3840.jpg");
  }
} */

.login-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  overflow: auto;
  background-color: @ColorSecGrey03;
  /* webpackIgnore: true */
  background-image: url("/images/pattern-modulo.svg");
  background-repeat: repeat;
  background-position: right bottom;
  background-size: 70%;

  @media (max-width: @screenLarge) {
    margin-top: 60px; //altura do header fixo mobile
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f5f7f7;
    background: linear-gradient(
      335deg,
      rgba(245, 247, 247, 0.95) 0%,
      rgba(245, 247, 247, 1) 40%
    );
  }
}

.fields-box {
  /* margin: 18% 8.7% 4% 8.7%;
        max-width: 515px; */
  position: relative;
  margin-left: 4rem;
  width: 430px;
}

.login-box {
  .brand {
    width: 240px;
    height: 28px;
    margin-bottom: 2rem;
  }
}

.form-login {
  margin-top: 2rem;
}

.form-login > div {
  margin-top: 1.75rem;
  position: relative;
}

.form-login input {
  width: 100%;
}

.header-text {
  font-size: 22px;
  line-height: 22px;
}

.forgot-pass-box {
  margin-top: 1rem;
}

.forgot-pass-link {
  margin-top: 20px;
  font-size: 14px;
}

.error-message {
  margin-top: 24px;
  color: @ColorError;
  font-size: 1rem;
}

.button-box {
  margin: 1rem 0 2rem 0;
  text-align: right;
}

.button {
  min-width: 200px;
}

.action-navigation {
  margin-top: 1.25rem;
  font-weight: 300;
  text-align: center;

  a {
    font-weight: 400;
    text-transform: uppercase;
  }
}

.main-box-md {
  // padding-top: 1rem;
  background-color: #f5f7f7;

  h2 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 20px;
  }

  .banner-box {
    display: none;
  }

  .login-box {
    align-items: flex-start;
    width: 100%;
    background-image: none;
  }
  .fields-box {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 12.5px 15px 15px 15px;
  }

  .header-text {
    font-size: 16px;
  }

  .form-login {
    margin-top: 0rem;
  }

  .form-login > div {
    margin-top: 1.5rem;
  }

  button {
    width: 100%;
  }
}
.input-icon-prepend {
  right: 1px;
  width: 18px;
  height: 18px;
}
.error-forgot-password-link {
  font-size: 12px;
  color: #721c24;
  text-decoration: underline;
}
</style>
