<template>
  <div 
    class="main-box"
    :class="$mq==='md' || $mq==='sm' ? 'main-box-md' : ''" 
  >
    <div
      class="left-box"
      :class="scrollLeft ? 'scrollleft':''"
    >
      <div>                
        <LogoExato
          v-if="showLogo"
          logo-color="white"
        />
        <slot name="left" />
      </div>
    </div>
    <div class="right-box">
      <div class="fields-box">
        <div class="fiels-box-overflow">
          <div class="fields-box-align">
            <div>
              <slot name="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    const LogoExato = () =>
    import(
        /* webpackChunkName: "LogoExato" */ "@/components/logo/logo-exato.vue"
    );

    export default {

        components: {
            LogoExato,
        },
        props: {
            showLogo: {
                type: Boolean,
                required: false,
                default: false
            },

            scrollLeft: {
                type: Boolean,
                required: false,
                default: false
            }
        },
    }
</script>

<style lang="less" scoped>
    @import '../../styles/colors.less';
    @import "../../styles/screen-size.less";

    .left-box {
        .brand {
            width: 210px;
            height: 24px;     
            margin-bottom: 1rem;
        }
    }

    .main-box {
        height: 100%;

    }

    .main-box > div > img:first-child {
        margin-top: 12px;
    }

    .scrollleft {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .left-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        height: 100%;
        float: left;
        text-align: right;
        background-color: @ColorPriTurquoise;
    }

    .left-box > div {
/*         margin-top: 18%;
        margin-right: 8.7%;
        margin-left: 8.7%;
        display: inline-block; */
        width: 400px;
        margin-right: 4rem;
        text-align: left;
        color: @ColorPriWhite;
    }    

    .right-box {
        position: relative;
        display: flex;        
        align-items: center;
        width: 50%;
         height: 100%;
       /* display: inline-block; */
        background-color: @ColorSecGrey03;
        /* webpackIgnore: true */
        background-image: url('/images/pattern-modulo.svg');
        background-repeat: repeat;
        background-position: right bottom;
        background-size: 70%;
        //overflow: auto;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #f5f7f7;            
            background: linear-gradient(335deg, rgba(245,247,247,.95) 0%, rgba(245,247,247,1) 40%);             
        }
    }

    .fields-box {
        /* margin: 18% 8.7% 5% 8.7%; */
        position: relative;
        margin-left: 4rem;
        width: 100%;

        overflow: auto;
        height: 100%;
    }

    .fiels-box-overflow {
        //overflow: auto;
        width: 430px;
        @media (min-width: @screenLarge) {
            height: 100%;
        }
    }

    .fields-box-align {
        height: 100%; 
        // não está bem resolvido. No mobile se não fixar, corta uma parte
        min-height: 780px;

        > div {
            width: 100%;
        }

        @media (min-width: @screenLarge) {
            display: flex;
            align-items: center;
            min-height: 658px;
            height: 100%;            
        }
    }

    /* Ajustes mobile */

    .main-box-md {

        .left-box {
            display: none;
        }

        .right-box {
            align-items: flex-start;
            width: 100%;
            background-image: none;   

            @media (max-width: @screenLarge) {           
                margin-top: 60px; //altura do header fixo mobile
            }
        }

        .fields-box {
            //width: auto;
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
            padding: 12.5px 15px 15px 15px;
        }

        .fiels-box-overflow {
            max-width: 100%;            
        }
    }

</style>
